import { Dispatch } from 'redux';
import { patch } from 'incremental-dom';
import { button, div, img } from '../../../render/html';
import { mapBooleanAttributes } from '../../../util/mapBooleanAttributes';
import {
  BUTTON_ABORT,
  BUTTON_CLOSE,
  DATENSCHUTZ_FEHLER_TEXT_SAFE_HTML,
  DATENSCHUTZ_FEHLER_TITLE,
  PRIVACY_ACCEPT,
  PRIVACY_REJECT,
  PRIVACY_REJECTED_HEADER,
  PRIVACY_REJECTED_SAFE_HTML,
} from '../../../texts';
import { setChatbotPrivacyAccepted } from '../../../state/sessionStore';
import { chatbotButtonImageData } from './chatbotButtonData';
import { ID_CHATBOT_BUTTON } from './renderChatbotButtonUnloaded';
import { CHATBOT_PRIVACY_ACCEPTED } from '../../../reducer/chatbotEvents';
import { ID_ASK_PRIVACY_MODAL, renderModal } from '../modal/renderModal';

const ID_ASK_PRIVACY_WRAPPER = 'hzd_vwp_chatbot_ask_privacy_wrapper';

export function renderChatbotAskPrivacyButton(dispatch: Dispatch) {
  return div({ class: 'vwp-chatbot-button-wrapper' }, [
    button(
      mapBooleanAttributes({
        class: 'vwp-chatbot-button',
        onclick: handleAskPrivacyButtonClicked(dispatch),
        src:
          window.hzd_og_data?.datenschutz.chatbotButtonSrc ||
          chatbotButtonImageData,
      }),
      [
        img({
          class: 'w-100 h-100',
          src:
            window.hzd_og_data?.datenschutz.chatbotButtonSrc ||
            chatbotButtonImageData,
        }),
      ],
    ),
  ]);
}

function handleAskPrivacyAcceptedButtonClicked(dispatch: Dispatch) {
  return () => {
    dispatch(CHATBOT_PRIVACY_ACCEPTED());
    setChatbotPrivacyAccepted();
    // Zeit lassen zum Rendern des Buttons
    setImmediate(() => {
      const chatbotButton = document.getElementById(ID_CHATBOT_BUTTON);
      if (chatbotButton) {
        chatbotButton.click();
      }
    });
  };
}

function handleAskPrivacyRejectedButtonClicked(outer: HTMLDivElement) {
  patch(
    outer,
    renderModal(PRIVACY_REJECTED_HEADER, PRIVACY_REJECTED_SAFE_HTML, [
      button(
        {
          type: 'button',
          class: 'btn btn-primary shadow',
          'data-bs-dismiss': 'modal',
        },
        [BUTTON_CLOSE],
      ),
    ]),
  );
  const modalElement = document.getElementById(ID_ASK_PRIVACY_MODAL);
  if (modalElement) {
    const modal = new window.bootstrap.Modal(modalElement);
    modalElement.addEventListener('hidden.bs.modal', () => {
      modal.dispose();
      document.body.removeChild(outer);
    });
    modal.show();
  }
}

function handleAskPrivacyButtonClicked(dispatch: Dispatch) {
  return () => {
    const texts = window.hzd_og_data?.datenschutz.chatbot;
    if (!document.getElementById(ID_ASK_PRIVACY_WRAPPER)) {
      const outer = document.createElement('div');
      outer.id = ID_ASK_PRIVACY_WRAPPER;
      document.body.appendChild(outer);
      let rejected = false;

      const buttons = texts
        ? [
            button(
              {
                type: 'button',
                class: 'btn btn-primary shadow',
                'data-bs-dismiss': 'modal',
                onclick: handleAskPrivacyAcceptedButtonClicked(dispatch),
              },
              [PRIVACY_ACCEPT],
            ),
            button(
              {
                type: 'button',
                class: 'btn btn-primary shadow',
                'data-bs-dismiss': 'modal',
                onclick: () => {
                  rejected = true;
                },
              },
              [PRIVACY_REJECT],
            ),
          ]
        : [
            button(
              {
                type: 'button',
                class: 'btn btn-primary shadow',
                'data-bs-dismiss': 'modal',
                onclick: () => {
                  rejected = true;
                },
              },
              [BUTTON_ABORT],
            ),
          ];
      patch(
        outer,
        renderModal(
          texts?.title ?? DATENSCHUTZ_FEHLER_TITLE,
          texts?.textSafeHtml ?? DATENSCHUTZ_FEHLER_TEXT_SAFE_HTML,
          buttons,
        ),
      );
      const modalElement = document.getElementById(ID_ASK_PRIVACY_MODAL);
      if (modalElement) {
        const modal = new window.bootstrap.Modal(modalElement);
        modalElement.addEventListener('hidden.bs.modal', () => {
          modal.dispose();
          if (rejected) {
            outer.innerHTML = '';
            handleAskPrivacyRejectedButtonClicked(outer);
          } else {
            document.body.removeChild(outer);
          }
        });
        modal.show();
      }
    }
  };
}
