import {
  SERVER_URL_LEISTUNG,
  SERVER_URL_LEISTUNG_SUCHE_STATISTIC,
} from '../../constants';
import { URL_REPLACED } from '../../reducer/events';
import { deepEquals } from '../../util/deepEquals';
import { logError } from '../../util/logError';
import { scrollToAnchor } from '../../util/scrollIntoView';
import { isAbortError, isErrorWithStatus, postNoRedirect } from '../util/fetch';
import { fetchStatistics } from '../util/fetchStatistics';
import {
  LEISTUNG_ERROR,
  LEISTUNG_FINISHED,
  LEISTUNG_NOT_FOUND,
  LEISTUNG_STARTED,
} from './detailEvents';
import type { LeistungRequestWithRegschl } from './detailTypes';
import type { State } from '../../state/createInitialState';
import type { SimpleStore } from '../../state/SimpleStore';
import { abortRequestFor } from '../util/abortRequestFor';
import type { HtmlPageLeistung } from '../htmlTypes';

export function buildLeistungsseitenRequest(state: State) {
  const leistungId = state.leistung.request?.leistung_id;
  const regschl = state.citySearch.selectedOrt.id;
  return {
    leistung_id: leistungId ?? '',
    regschl,
  };
}

export function loadLeistungIfNotLoading(
  simpleStore: SimpleStore,
  request: LeistungRequestWithRegschl,
): void {
  const state = simpleStore.getState();
  const sucheRequest = state.search.request;

  const currentLoadingForRequest = state.abortRequest.leistung?.forRequest;
  if (!deepEquals(request, currentLoadingForRequest)) {
    const { abortRequest, result } = postNoRedirect<
      LeistungRequestWithRegschl,
      HtmlPageLeistung
    >({
      url: SERVER_URL_LEISTUNG(),
      data: request,
      flags: simpleStore.getState().flags,
    });
    if (sucheRequest.searchTerm) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetchStatistics({
        url: SERVER_URL_LEISTUNG_SUCHE_STATISTIC(),
        data: {
          ...request,
          suche: sucheRequest.searchTerm,
          regschl_wahl: sucheRequest.regschlWahl,
          pv_lage: sucheRequest.filter.pvLage,
          filter_type: sucheRequest.filter.type,
        },
      });
    }
    abortRequestFor(state.abortRequest.leistung);
    simpleStore.dispatch(
      LEISTUNG_STARTED({ abortRequest, forRequest: request }),
    );
    result
      .then((results) => {
        const latestRequest =
          simpleStore.getState().abortRequest.leistung?.forRequest;
        if (deepEquals(latestRequest, request)) {
          const alternateLeistungId = results.page.data.leistung.id;
          simpleStore.dispatch(
            LEISTUNG_FINISHED({
              forRequest: {
                ...request,
                leistung_id: alternateLeistungId ?? request.leistung_id,
              },
              patch: results.page,
            }),
          );
          // die LeistungId vom Server kann sich vom Request unterscheiden, daher muss
          // hier die URL_REPLACED dispatched werden, um die Url zu aktualisieren
          simpleStore.dispatch(URL_REPLACED());
          scrollToAnchor(simpleStore);
        }
      })
      .catch((e: Error) => {
        const latestRequest =
          simpleStore.getState().abortRequest.leistung?.forRequest;
        if (deepEquals(latestRequest, request)) {
          // request war nicht erfolgreich
          if (isAbortError(e)) {
            // Request wurde absichtlich (abortRequest()) abgebrochen --> nichts zu tun
          } else if (isErrorWithStatus(e) && e.status === 404) {
            // keine Daten gefunden
            simpleStore.dispatch(LEISTUNG_NOT_FOUND(request));
          } else {
            // sonstiger Fehler
            logError(e);
            simpleStore.dispatch(LEISTUNG_ERROR(e));
          }
        }
      });
  }
}
